.loader__bg-content {
    display: none;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
}

.loader__content {
    width: 300px;
    height: 70px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.loader {
    border: 6px solid #FFF;
    border-radius: 50%;
    border-top: 6px solid #4eaf79;
    width: 70px;
    height: 70px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: 0 auto;
    margin-bottom: 20px;
}

.loader__message {
    margin: 0;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 25px;
    max-width: 300px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
