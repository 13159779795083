a.change-column-item {
    padding: 0 10px;
    display: block;
    float: left;
    height: 20px;
    margin: 6px 0;
    cursor:pointer;
}

.change-column-span {
    width: 10px;
    height: 20px;
    background: #222;
    display: inline-block;
}

.change-column-item:hover span,
    #column-item-active span{
    background: grey;
}
