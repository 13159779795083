#commercial-partners-list, #commercial-partner-detail {
    border: #AAAAAA 1px solid;
    min-height: 30em;
}

.thumbnail-partner {
    display: block;
    line-height: 1.42857143;
    background-color: #fff;
    height: 100%;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
}

.thumbnail-partner-section {
    margin: 20px 0px;
}

.thumbnail-partner-section-image {
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px #ddd solid;
    border-radius: 4px;
    flex-direction: column;
    overflow: hidden;
}

.thumbnail-partner-image {
    height: 140px !important;
    width: 100%;
}

.detail-partner-section {
    font-family: sans-serif;
}

.partner-description-title {
    font-weight: bold !important;
    font-size: 20px;
}

.partner-description-section{
    margin-top: 30px;
}

.detail-partner-top-section{
    height: 140px;
}

.partner-name-section, .partner-badge-section {
    font-family: sans-serif;
    height: 100%;
    margin: 20px 0px;
    display: inline-flex;
}

.partner-name {
    font-size: 28px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
}