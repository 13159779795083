.cart__header {
    padding: 2px;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
}

.cart__main {
    width: 100%;
    position: relative;
}

.cart__main .header-logo {
    position: absolute;
    right: auto;
    top: 0;
    bottom: 0;
    margin: auto;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
}

.cart__main .header-logo > .header-logo-vcenter{
    display: table-cell;
    height: 72px;
    vertical-align: middle;
}

.cart__main .header-logo img {
    max-width: 190px;
    max-height: 65px;
}

.cart-header__security {
    float: right;
    margin: 19px 0;
}

.cart-header__security span {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 14px;
    line-height: 1.2;
    text-align: right;
    margin: 0;
    margin-right: 4px;
}

.cart-header__security span b {
    font-family: Gotham Medium;
}

.cart-header__security img {
    height: 30px;
    margin: 0;
}

/* crossbrowser */

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
    @media {
        .cart-header__security span b {
            font-family: "Open Sans";
            font-weight: 600;
        }
        .cart-header__security span {
            font-family: "Open Sans";
        }
    }
}
