.smp-signature {
	font-family: 'Open Sans';
	font-size: 12px;
	font-weight: 400;
}

.smp-signature:hover {
	text-decoration: none;
}

.smp-signature--darkbg,
.smp-signature--darkbg:hover {
	color: white;
}

.smp-signature--lightbg,
.smp-signature--lightbg:hover {
	color: black;
}

.smp-signature--darkbg:hover,
.smp-signature--lightbg:hover {
	text-shadow: 0px 0px 0px;
}

