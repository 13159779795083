.checkout-mail {
    background-color: #ecf1f8;
    display: block;
    width: 100%;
    padding: 40px 0;
    height: auto;
    min-height: 638px;
}

.checkout-mail__main {
    border-radius: 5px;
    border: 1px solid #ededed;
    background-color: #ffffff;
    box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.05);
    width: 890px;
    margin: 0 auto;
    padding: 60px 80px;
}

.checkout-mail__title {
    font-family: Gotham Medium;
    color: #4c4d4f;
    font-size: 28px;
    line-height: 30px;
    margin-top: -6px;
    margin-bottom: 15px;
}

.checkout-mail__subtitle {
    font-family: Gotham;
    color: #9E9E9E;
    font-size: 16px;
    font-weight: 300;
}

.checkout-mail__form {
    padding: 20px 0 30px;
}

.checkout-mail__form label[for="authentication-mail"] {
    font-family: Gotham Medium;
    color: #4c4d4f;
    font-size: 15px;
}

.checkout-mail__input-content {
    width: 100%;
}

.authenticationEmail-content {
    width: 65%;
}

.authenticationEmail {
    border: 1px solid #1cade4;
    border-right: none;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    color: #4c4d4f;
    font-size: 15px;
    font-weight: 300;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.checkout-mail__btn {
    position: relative;
    width: 35%;
}

.authentication-submit {
    background-color: #058c41;
    border: 1px solid transparent;
    width: 100%;
    height: 50px;
    color: #FFF;
    font-size: 17px;
    font-weight: 700;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.checkout-mail__btn i.fa {
    position: absolute;
    top: 20px;
    right: 20px;
    margin: auto;
    color: #FFF;
    pointer-events: none;
}

.checkout-mail__input-social {
    margin-top: 0;
}

.checkout-mail__input-social .chk__label {
    margin: 11px 0;
}

.chk__social-login-mail .chk__social-login {
    margin: 0 0 8px;
}

.chk__social-login-span {
    color: #888;
}

input[name="social-login-Google"],
input[name="social-login-Facebook"] {
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    background-color: #FFF;
    border-style: solid;
    height: 50px;
    font-family: Gotham Medium;
    color: #4c4d4f;
    font-size: 15px;
    width: 49%;
    padding-left: 45px;
    background-repeat: no-repeat;
    background-position: 45px center;
    background-size: 26px;
}

input[name="social-login-Google"] {
    background-image: url("../img/icon/common/auth-google.svg");
}

input[name="social-login-Facebook"] {
    background-image: url("../img/icon/common/auth-facebook.svg");
}

.checkout-mail__cart-link {
    font-family: Gotham Medium;
    color:  #4c4d4f;
    font-size: 15px;
}

.checkout-mail__cart-link a {
    color:  #1cade4;
    display: inline-block;
}

.checkout-mail__cart-footer {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
}

@media (max-width: 991px) {
    .checkout-mail {
        padding: 95px 0;
    }
}

@media (max-width: 840px) {
    .checkout-mail {
        background: transparent;
        padding: 65px 0;
        min-height: 520px;
    }
    .checkout-mail__main {
        width: 100%;
        border: 0;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        padding: 30px 60px;
    }
    .checkout-mail__btn input {
        width: 100%;
    }
    .checkout-mail__form {
        padding: 30px 0;
    }
}

@media (max-width: 600px) {
    .authenticationEmail-content {
        width: 100%;
        margin-bottom: 8px;
    }
    .authenticationEmail {
        border: 1px solid #1cade4;
        border-right: 1px solid #1cade4;
        border-radius: 5px;
    }
    .checkout-mail__btn {
        width: 100%;
    }
    input[name="authentication-submit"] {
        border-radius: 5px;
    }
    .checkout-mail {
        height: auto;
    }
    .checkout-mail__cart-footer {
        position:relative;
        left: auto;
        bottom: auto;
    }
}

@media (max-width: 450px) {
    .checkout-mail__main {
        padding: 20px;
    }
}

/* crossbrowser */

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
    @media {
        ::i-block-chrome,
        .checkout-mail__subtitle {
            font-family: "Open Sans";
        }

        ::i-block-chrome,
        .checkout-mail__title,
        .checkout-mail__form label[for="authentication-mail"],
        input[name="social-login-Google"],
        input[name="social-login-Facebook"],
        .checkout-mail__cart-link  {
            font-family: "Open Sans";
            font-weight: 600;
        }
    }
}
