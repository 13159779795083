article.product .col-sm-1 {
    padding-right: 8px;
}

.product-thumbnail-item {
    position: relative;
}

.product-thumbnail-item-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.product-thumbnail-item__video:before {
    content: "\f144";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 30px;
    width: 27px;
    height: 30px;
    line-height: 1;
    color: #AAA;
}

.product-image-main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
}

.product-video {
    list-style: none;
    display: none;
    z-index: -1;
}

.product-video-visible {
    display: block !important;
    position: relative;
    z-index: 1;
}

.product-video,
.product-video iframe {
    width: 100%;
    height: 100%;
}

.product-photo-main {
    position: relative;
    background-color: #fff;
    overflow: hidden;
}

#zoom .image-main-toggle {
    max-height: 250px;
    width: initial;
}

.owl-stage {
    position: relative;
}

.total-photos {
    position: absolute;
    font-size: 14px;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    border-radius: 4px;
    padding: 4px 8px;
    pointer-events: none;
    z-index: 2;
    left: 12px;
    width: 63px;
    margin: 0 auto;
    bottom: 40px !important;
}

#zoom .owl-dots {
    position: absolute;
    top: 186px;
    left: 12px;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}

#zoom .owl-dot {
    display: inline-block;
    margin-right: 6px;
}

#primary-banner .owl-dots {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: fit-content;
}

#primary-banner .owl-dot {
    display: inline-block;
    margin: 0 3px;
}

.owl-dot.active span{
    background: #555;
}

.owl-dot span {
    width: 10px;
    height: 10px;
    background: #869791;
    display: block;
    border-radius: 30px;
}

.pswp__button.pswp__button--close {
        background-image: url(../img/default-skin.svg);
}

#mobile-play-video {
    display: table;
    border: 2px solid #555;
    border-radius: 50%;
    padding: 6px 5px 6px 9px;
    position: relative;
    top: 8px;
}

#mobile-play-video i {
    color: #555;
}

#mobile-video {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 999;
    overflow: hidden;
}

#mobile-video i {
    display: table;
    color: #fff;
    font-size: 22px;
    padding: 15px 15px 18px;
    margin-left: auto;
}

#mobile-video iframe {
    width: 100%;
    padding: 0 15px;
    height: calc(100% - 55px - 15px);
}

#mobile-video iframe {
    width: 100%;
    padding: 0 15px;
    height: calc(100% - 55px - 15px);
}
