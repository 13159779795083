.cart-chk__footer {
    background: #FFF;
    padding-top: 2em;
}

.cart-chk__payment {
    margin-right: 50px;
}

.cart-chk__certificate {
    margin-right: 50px;
}

.cart-chk__payment span,
.cart-chk__certificate span {
    font-family: Gotham Medium;
    color: #333333;
    font-size: 13px;
    margin-right: 20px;
}

.cart-chk__line {
    border-top: 1px solid #a7a7a7;
    margin: 18px 0 10px;
}

.cart-chk__copyright,
.cart-chk__copyright a[title=Telefone],
.cart-chk__copyright a[title=E-mail]{
    font-family: Gotham;
    color: #333333;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 10px;
}

.cart-chk__copyright span {
    float: right;
}

.chk-mobile-footer {
    display: none;
}

@media (max-width: 719px) {
    .cart-chk__certificate span,
    .cart-chk__payment span {
        display: block;
        text-align: center;
        width: 100%;
    }
    .cart-chk__certificate {
        margin-right: 0;
    }
    .cart-chk__copyright {
        text-align: center;
    }
    .cart-chk__copyright span {
        float: none;
        display: block;
        padding: 20px 0;
        margin: 0 auto;
        width: 78px;
    }
}

@media (max-width: 550px) {
  .chk-desktop-footer {
      display: none;
  }
  .chk-mobile-footer {
      display: block;
  }
}

@media (max-width: 550px) {
    .cart-chk__payment {
        margin-right: 15px;
    }
}

@media (max-width: 372px) {
    .cart-chk__payment {
        margin-right: 10px;
    }
    .cart-chk__payment img {
        width: 160px;
    }
}

/* crossbrowser */

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
    @media {
        .cart-chk__payment span,
        .cart-chk__certificate span  {
            font-family: "Open Sans";
            font-weight: 600;
        }
        .cart-chk__copyright{
            font-family: "Open Sans";
            color: #333333;
        }
        .cart-chk__copyright a[title=Telefone],
        .cart-chk__copyright a[title=E-mail] {
            font-family: "Open Sans";
            color: #333333;
        }
    }
}
