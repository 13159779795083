.my-orders__content-info {
    padding: 15px 25px;
    border-right: 1px solid #CCC;
}

.my-orders__content {
    border: 1px solid #CCC;
    padding: 30px;
}

.my-orders__content-info {
    padding: 15px 30px;
    border-right: 1px solid #CCC;
    min-height: 125px;
}

.my-orders__content .my-orders__content-info:last-child {
    border: none;
}

.my-orders__content-info .fa-cart-plus {
    color: #444;
    font-size: 30px;
}

.address__content {
    border: 1px solid #CCC;
    padding: 40px;
    margin-bottom: 30px;
    position: relative;
    min-height: 188px;
}

.address__content.new {
    border-color: #4eaf79;
}

.address__content span {
    color: #888;
}

.address__actions {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.address__edit,
.address__remove {
    font-size: 18px;
    color: #888;
}

.address__content.new i {
    color: #4eaf79;
    font-size: 30px;
    margin: 16px auto;
    display: block;
    width: 30px;
}

.address__content.new h2 {
    text-align: center;
    font-size: 25px;
    color: #4eaf79;
    font-weight: 700;
}

.customer-form__content {
    padding: 40px;
    border: 1px solid #CCC;
}

.customer-form__content h2 {
    margin-bottom: 5px;
}

.customer-form__content p {
    color: #AAA;
}

.customer-form__content hr {
    margin: 25px 0;
}

.customer-form__content h4 {
    color: #666;
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: 1px solid #CCC;
}

.customer-form__content h4.object_number,
.customer-form__content h4.itens {
    border-bottom: 0;
}

.order__title-line > div {
    background: #DDD;
    padding-bottom: 8px;
    padding-top: 8px;
    color: #666;
    font-weight: 600;
}

.order__item-line {
    border-bottom: 1px solid #CCC;
}

.order__item-line > div {
    padding-top: 10px;
    padding-bottom: 10px;
}

.order__item-line > div.col-sm-2 {
    padding-top: 30px;
}

.order__item-line p {
    max-width: 380px;
    margin-left: 20px;
    margin-bottom: 0;
    color: #666;
}

a[data-toggle="modal"].order-detail__link-btn {
    color: white;
    font-family: inherit;
    font-size: inherit;
}

.order-detail__pix-dialog button{
    color: white;
    text-transform: uppercase;
    background: #011C27 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    padding: 10px;
    border-style: hidden;
}

.order-detail__pix-dialog .modal-footer{
    border-top: none;
    padding: 25px 0 0 0;
}

@media (max-width: 1200px) {
    .my-orders__content {
        padding: 18px;
    }
    .my-orders__content-info {
        padding: 15px 16px;
    }
    .address__content {
        min-height: 225px;
    }
    .address__content.new i {
        margin: 26px auto;
    }
    .order__item-line p {
        max-width: 330px;
    }
}

@media (max-width: 992px) {
    .my-orders__content {
        padding: 18px 2px;
    }
    .my-orders__content-info {
        padding: 10px 5px;
        max-width: 170px;
    }
    .address__content {
        padding: 20px;
    }
    .address__content.new i {
        margin: 55px auto 15px;
    }
    .order__item-line p {
        max-width: 225px;
        font-size: 13px;
        line-height: 1.1;
        margin-left: 15px;
    }
}

@media (max-width: 768px) {
    .my-orders__content-info {
        padding: 15px 30px;
        max-width: 200px;
    }
}

@media (max-width: 674px) {
    .my-orders__content-info {
        padding: 15px;
        max-width: 180px;
    }
}

@media (max-width: 628px) {
    .order__item-line p {
        max-width: 155px;
    }
}

@media (max-width: 574px) {
    .my-orders__content-info {
        max-width: 130px;
    }
}

@media (max-width: 508px) {
    .address__content.new i {
        margin: 35px auto 15px;
    }
    .order__title-line {
      display: none;
    }
    .order__item-line .col-xs-7.col-sm-8 {
        width: 100%;
    }
    .order__item-line p {
        max-width: 75%;
    }
    .order__item-line .col-xs-1.col-sm-1.col-md-1.col-lg-2:before {
        content: "Qtd:";
    }
    .order__item-line .col-xs-1.col-sm-1.col-md-1.col-lg-2 {
        width: 65px;
    }
}

@media (max-width: 474px) {
    .my-orders__content-info {
        width: 100%;
        max-width: initial;
        min-height: initial;
        padding: 5px 15px;
        border: none;
    }
    .my-orders__content-info br {
        display: none;
    }
}

@media (max-width: 440px) {
    .customer-form__content {
        padding: 25px;
    }
    .customer-form__content .col-xs-6.col-sm-6.col-md-4 {
        width: 100%;
        margin-bottom: 20px;
    }
    .address__main {
        width: 100% !important;
    }
    .order-details__return {
        display: inline-block;
        position: relative;
        margin-top: 30px;
        margin-left: 15px;
    }
}

@media (max-width: 336px) {
    .order__item-line p {
        max-width: 70%;
    }
}
