.fade-transition {
    transition-property: opacity;
    transition-duration: .3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    overflow: hidden;
}
/* .fade-enter defines the starting state for entering */
/* .fade-leave defines the ending state for leaving */
.fade-enter,
.fade-leave {
    opacity: 0;
}

.chk__stage {
    display: inline-block;
    vertical-align: middle;
    margin-right: .75em;
    width: 30px;
    height: 30px;
    border: 1px solid #515151;
    border-radius: 50%;
    font-size: 16px;
    color: #4eaf79;
    font-weight: 600;
    line-height: 1.8;
}

input.error,
select.error {
    border-color: #ff2727;
}

input[type="radio"] {
    margin: initial;
}

.checkout-area .container {
    min-height: 600px;
}

.chk-edit-address {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: -10000px;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 0 10000px rgba(0,0,0,.75);
    background-color: #fff;
    width: 50%;
    max-width: 550px;
    min-width: 350px;
    z-index: 1;
    padding: 0 1em 1em;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    transition: top .3s ease-in-out, opacity .4s ease;
}

.chk-edit-address--active {
    top: 50%;
    visibility: visible;
    opacity: 1;
}

.chk-edit-address__close {
    position: absolute;
    top: 1em;
    right: 1em;
    color: #515151;
    background-color: transparent;
    border: none;
}

.chk-heading {
    font-family: "Open Sans";
    color: #515151;
    font-size: 30px;
    padding-bottom: .5em;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
}

.chk__select-options {
    margin-top: 1.5em;
    margin-bottom: 1.75em;
}

.chk-payment__cart {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
}

.chk-shipping-total {
    font-size: 16px;
    font-weight: 600;
}

.chk__order-total {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 17px;
    font-weight: 300;
}

.chk__order-total-price {
    font-family: Gotham Medium;
    color: #1cade4;
    font-weight: 500;
}

.chk-section--has-separator {
    position: relative;
}

.chk-section--has-separator:before,
.chk-section--has-separator:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    min-height: 70vh;
    width: 2px;
    background-color: #ecf1f8;
}

.chk-section--has-separator:before {
    right: 100%;
    right: calc(100% - 1px);
}

.chk-section--has-separator:after {
    left: 100%;
    left: calc(100% - 1px);
}

.header-logo img {
    max-width: 100%;
}

.chk__cvv-icon-content {
    position: relative;
}

.chk-cvv label {
    cursor: help;
}

.chk-cvv label i {
    font-size: 1.3em;
}

.chk__cvv-icon {
    background: #1cade4;
    font-family: Gotham Medium;
    font-weight: 500;
    color: #FFF;
    line-height: 20px;
    width: 20;
    height: 20px;
    border-radius: 22px;
    display: block;
    margin: 9px 0;
}

.chk__cvv-information {
    visibility: hidden;
    position: absolute;
    top: -15px;
    left: 30px;
    border: 1px solid #CCC;
    padding: 6px;
    width: 194px;
    z-index: 2;
}

.chk__cvv-icon:hover > .chk__cvv-information {
    visibility: visible;
}

.chk-cvv__explanation-img {
    width: 80px;
}

.chk__cvv-msg {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    width: 85px;
    margin-left: 5px;
}

/* LOADER INICIAL */
#loader-layer {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: #FFF;
    z-index:99999;
}

.spinner {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: 9999999;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #555;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

/* Erros */
#not-authenticated-panel > .alert-danger {
    float: left;
    width: 100%;
}

/* Posicionamento dos campos */
.borda ul .col-md-12, #dados-pessoais ul > .col-md-6, #dados-pessoais ul > .col-md-12 {
    position: initial;
}

#customer a.pull-left {
  margin-top:35px;
}

.label__card-expiring-date {
    height:10px;
}

.label__card-expiring-date, .label__card-verification-number {
    padding: 0 !important;
}

#customer-personal-information .ip__form-control {
    color: #585858;
}

#message_text {
    resize: none;
    height: 110px;
}

.error,
.invalidDocumentNumber small {
    font-weight: 500;
    font-size: 12px;
    color: #ff2727;
    width: 100%;
    letter-spacing: 1px;
}

.form-group {
    margin: 0 !important;
    min-height: 72px;
}

.data-exp {
    padding-top: 15px;
}

.address-content {
    margin-bottom: 30px;
    border: 1px solid #CCC;
    padding: 10px 20px;
    border-radius: 4px;
    color: #444;
    font-size: 13px;
}

#address-information #address-section {
    word-wrap: break-word;
}

.payment__cart {
    border-bottom: 2px solid #ecf1f8;
    padding-bottom: 10px;
    margin-top: 20px;
}

.payment__cart:after {
    content: "\f055";
    font-family: 'FontAwesome';
    font-size: 18px;
    float: right;
    margin-top: 4px;
    color: #1cade4;
}

.payment__cart.active:after{
    content: "\f056";
}

.chk__heading {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 18px;
    font-weight: 300;
}

.chk__form-control {
    border: 1px solid #d1d1d1;
    font-family: Gotham;
    color: #4C4D4F;
    font-size: 13px !important;
    font-weight: 300;
    width: 100%;
    height: 42px;
    padding-left: 1em;
    margin-bottom: 3px;
    border-radius: 3px;
}

select.chk__form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    position: relative;
}
select.chk__form-control::-ms-expand {
    display: none;
}

.chk__card-expiring-date {
    width: 100%;
}

.chk__label {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 13px;
    font-weight: 300;
}

.chk__label:after {
    content: "*";
    font-family: Gotham;
    color: transparent;
    font-size: 16px;
    font-weight: 300;
}

.chk__label-required:after {
    content: "*";
    color: #1cade4;
}

.chk__link {
    font-family: Gotham;
    color: #1cade4;
    font-size: 13px;
    font-weight: 300;
}

.chk__link:hover {
    cursor: pointer;
}

.chk__pwd-link {
    text-align: right;
    margin-top: 5px;
}

.chk__join-link {
    font-family: Gotham Medium;
}

.chk__logout {
    font-family: Gotham Medium
}

/* Painel de exibição da mensagem sobre política de privacidade e termos de uso do site */
.chk__statement {
    margin-top: 15px;
    margin-bottom: 10px;
}

.chk__button {
    font-family: Gotham Medium;
    color: #FFF;
    font-size: 17px;
    width: 100%;
    height: 53px;
    border-radius: 5px;
    border-color: transparent;
    margin-top: 8px;
}

.chk__button i {
    font-size: 10px;
}

.chk__button-blue {
    background-color: #1cade4;
}

.chk__button-green {
    background-color: #058c41;
}

.chk__button-disabled {
    background-color: #e1e1e1;
}
.chk__button-white {
    color: #058c41;
    border: 2px solid #058c41;
    font-weight: bold !important;
    background-color: white;
}
.chk__btn-finish {
    position: relative;
}

.chk__btn-finish i {
    position: absolute;
    top: 19px;
    right: 22px;
}

.chk__separator {
    border-color: #ecf1f8;
    border-width: 2px;
}

.chk__social-login {
    margin: 19px 0;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    width: 210px;
    height: 45px;
    background-color: #4267B2;
    font-family: Gotham Medium;
    color: #fff;
    font-size: 13px;
    background-image: url(../img/icon/common/login-facebook.png);
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: 11px center;
    padding-left: 40px;
}

.chk__customer-box {
    border-radius: 5px;
    border: 1px solid #D1D1D1;
    padding: 10px 14px;
    position: relative;
    margin-bottom: 5px;
}

.chk__customer-information {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 13px;
    line-height: 22.34px;
    padding: 0;
    margin: 5px 0;
    max-width: 86%;
}

#payment__cart {
    max-width: 100%;
}

.chk__customer-information strong {
    font-family: Gotham Medium;
    font-weight: 500;
}

.chk__edit-link {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 11px;
    text-decoration: underline;
    position: absolute;
    bottom: 14px;
    right: 14px;
    cursor: pointer;
}

.chk__info-small {
    font-size: 12px;
}

.chk__half-size-input {
    width: 49%;
    padding: 0 1%;
    vertical-align: top;
}

.chk__full-size-input {
    width: 100%;
    padding: 0 2%;
}

.chk__cep-label a.chk__link {
    margin-bottom: 2px;
    margin-left: 44px;
}

.chk__cep-input {
    width: 56%;
}

.chk-payment__text {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 11px;
    font-weight: 300;
}

.chk-payment__text b {
    font-weight: 700;
}

.chk-payment__text strong {
    font-family: Gotham Medium;
    font-size: 13px;
}

.chk-payment__store-name {
    font-family: Gotham Medium;
    color: #1cade4;
    font-size: 13px;
}

.chk-payment__line {
    width: 100%;
    border-bottom: 1px dotted #4c4d4f;
    padding: 12px 0;
}

.chk-payment__col-7,
.chk-payment__col-25,
.chk-payment__col-5 {
    float: left;
}

.chk-payment__col-7 {
    width: 62%;
}

.chk-payment__col-25 {
    width: 19%;
}

.chk-payment__col-5 {
    width: 38%;
    text-align: right;
}

.chk-payment__shipping {
    padding: 5px 0;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    border-bottom: 2px solid #ecf1f8;
}

.chk-section--has-separator .chk__waiting-step:first-child {
    margin-top: 58px;
}

.chk__waiting-step {
    display: inline-block;
    width: 100%;
}

.chk__waiting-step-main {
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    background-color: #fbfbfb;
    position: relative;
    width: 96%;
    margin: 0 0 0 4%;
    padding: 25px 0 25px 30px;
}

.chk__waiting-step__description {
    font-family: Gotham Medium;
    color: #4c4d4f;
    font-size: 14px;
}

.chk__waiting-step__number {
    position: absolute;
    border-radius: 50%;
    background-color: #1cade4;
    width: 30px;
    height: 30px;
    font-family: Gotham;
    color: #FFF;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    line-height: 28px;
    left: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.chk__waiting-step__message {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 11px;
    margin: 3px 0;
}

.chk__full-content-select-icon {
    position: relative;
    width: 100%;
}

.chk__select-icon {
    color: 1cade4;
    font-size: 9px !important;
    position: absolute;
    top: 14px;
    right: 15px;
}

.chk__boleto-text {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
}

.chk__pix-text {
    font-family: Gotham;
    color: #4c4d4f;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    padding: 25;
    border: solid 1px #ddd;
    border-radius: 5px;
}

.chk__discount-alert {
    font-family: Gotham;
    color: #4c4d4f;
    background-color: #D1FFE6;
    font-size: 12px;
    line-height: 18px;
    padding: 15;
    border: solid 1px #ddd;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.chk__payment-btn {
    padding-left: 43px;
    background-repeat: no-repeat;
    background-position: 10px center;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
}

.chk__payment-btn:hover {
    cursor: pointer;
}

.chk__payment-btn.creditCard, .chk__payment-btn.multiCreditCard  {
    background-image: url(../img/icon/common/credit-card.png);
}
.chk__payment-btn.multiBoletoAndCreditCard  {
    background-image: url(../img/icon/common/boleto_cartao.svg);
}

.chk__payment-btn.boleto {
    background-image: url(../img/icon/common/boleto.png);
}

.chk__payment-btn.pix {
    background-image: url(../img/icon/common/pix/pix-logo.png);
}

.chk__payment-btn.pix.chk__payment-active {
    background-image: url(../img/icon/common/pix/pix-logo-white.png);
}

.chk__payment-active {
    background-color: #1cade4;
    color: #FFF;
}

.chk__payment-active.creditCard, .chk__payment-active.multiCreditCard {
    background-image: url(../img/icon/common/credit-card-active.png);
}

.chk__payment-active.multiBoletoAndCreditCard {
    background-image: url(../img/icon/common/boleto_cartao_active.svg);
}

.chk__payment-active.boleto {
    background-image: url(../img/icon/common/boleto-active.png);
}

.chk__label-cep {
    margin-top: 32px;
}

.chk__bigger-input{
    width: 65%;
    padding: 0 1%;
}

.chk__small-input{
    width: 30%;
    padding: 0 1%;
}

.chk__password {
    position: relative;
}

.chk__see-pwd {
    width: 26px;
    height: 14px;
    background-image: url(../img/icon/common/chk-eye.png);
    background-position: 0px 0px;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    top: 13px;
    right: 15px;
}

.chk__see-pwd-active {
    -webkit-filter: brightness(50%);
    filter: brightness(50%);
}

.chk__label + .chk__label {
    margin-left: 5em !important;
}

.chk__creditCard-input {
    width: 24%;
}

.chk__creditCard-input.year {
    margin-right: 5%;
}

.chk__creditCard-input.cvv_code {
    width: 32%;
}

.cvv_code-contemt {
    max-width: 82px;
}

#credit_card_ccv_code {
    width: 80px;
}

.chk__steps {
    position: absolute;
    top: 21px;
    left: 0;
    right: 0;
    pointer-events: none;
    text-align: center;
}

.chk__steps-description span {
    font-family: Gotham;
    color: #AAA;
    font-size: 13px;
    float: left;
}

.chk__steps-description span.chk__steps-item-active {
    color: #1cade4;
}

.chk__steps-description span.chk__steps-item-checked {
    color: #18619e;
}

.chk__steps-description span:nth-child(2) {
    margin: 0 100px;
}

.chk__steps-description span.cart-md-step {
    margin: 0 110px;
}

.chk__steps-description span.cart-step {
    margin-right: 100px;
}

.chk__steps-pointline {
    margin-top: 4px;
}

.chk__steps-dot {
    width: 9px;
    height: 9px;
    background: #CACACA;
    border-radius: 35px;
    float: left;
}

.chk__steps-dot.chk__steps-item-active {
    background: #1cade4;
}

.chk__steps-dot.chk__steps-item-checked {
    background: #18619e;
}

.chk__steps-line {
    float: left;
    width: 167px;
    height: 1px;
    background: #CACACA;
    margin-top: 3.5px;
}

.chk__steps-line.chk__steps-item-checked {
    background: #18619e;
}

.chk__pix_image-panel {
    display:block;
    margin: 10px 20px 30px 20px;
    text-align: center;
}

.header-logo.affiliates-logo {
    text-decoration: none;
}

.header-logo.affiliates-logo img {
    max-height: 49px;
}

.header-logo.affiliates-logo .header-affiliates-name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #4d4d4d;
    font-family: "SimplonBP", sans-serif;
    font-size: 15.6px;
    font-weight: 100;
    text-transform: uppercase;
}

.chk__oi-fiber-alert {
    border-radius: 5px;
    border: 1px solid #D1D1D1;
    padding: 10px 14px;
    position: relative;
    margin-bottom: 5px;
}

.chk__oi-fiber-alert-2 {
    border-radius: 5px;
    border: 1px solid #D1D1D1;
    padding: 10px 14px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 15px;
    background-color: #fcf8e3;
    border-color: #faedd2;
}

.chk__oi-fiber-alert-3 {
    float: right;
    max-width: 450px;
}

@media (max-width: 992px) {
    .checkout-area > .container > .alert.alert-danger.alert-dismissible,
    .checkout-area > .container > .alert.alert-success.alert-dismissible,
    .checkout-area > .container > .alert.alert-warning.alert-dismissible,
    .checkout-area > .container > .alert.alert-info.alert-dismissible
    {
        margin-top: 48px;
    }
}

@media (max-width: 1199px) {
    .chk__cep-input {
        width: 47%;
    }
    #payment-options .chk__half-size-input {
        width: 100%;
    }
    .chk__steps-description span.cart-md-step {
        margin: 0 60px;
    }
    .chk__steps-description span.cart-step {
        margin-right: 60px;
    }
    .chk__steps-description span:nth-child(2) {
        margin: 0 60px;
    }
    .chk__steps-line {
        width: 117px;
    }
    .chk__label + .chk__label {
        margin-left: 2.6em !important;
    }
    .chk__creditCard-input.cvv_code {
        width: 37%;
    }
    .chk__cep-label a.chk__link {
        margin-left: 10px;
        font-size: 10px;
    }
}

@media (max-width: 991px) {
    #payment-options .chk__half-size-input {
        width: 49%;
    }
    .chk__cvv-information {
        top: 26px;
        left: inherit;
        right: -40px;
        background: #FFF;
    }
    .formulario {
        margin-top: 35px;
    }
    .chk__steps {
        top: 100px;
    }
    .chk__label + .chk__label {
        margin-left: 11em !important;
    }
    .chk__cep-label a.chk__link {
        margin-left: 175px;
        font-size: 13px;
    }
}

@media (max-width: 731px) {
    .chk__cep-label a.chk__link {
        margin-left: 28%;
    }
}

@media (max-width: 670px) {
    .chk__cvv-information {
        right: -10px;
    }
}

@media (max-width: 582px) {
    .chk__label + .chk__label {
        margin-left: 0 !important;
    }
    .chk__creditCard-label label:first-child {
        width: 54%;
    }
}

@media (max-width: 470px) {
    .chk__steps-description span.cart-md-step {
        margin: 0 20px;
    }
    .chk__steps-description span.cart-step {
        margin-right: 20px;
    }
    .chk__steps-description span:nth-child(2) {
        margin: 0 20px;
    }
    .chk__steps-line {
        width: 77px;
    }
    .chk__cep-label a.chk__link {
        margin-left: 10%;
    }
}

@media (max-width: 395px) {
    .chk__half-size-input,
    #payment-options .chk__half-size-input {
        width: 99%;
    }
    .chk__payment-btn {
        padding-left: 45px;
        line-height: 1.2;
        text-align: left;
    }
    .chk-cvv__explanation-img {
        width: 100%;
        margin-bottom: 5px
    }
    .chk__cvv-information {
        padding: 10px;
        width: 120px;
        right: -55px;
    }
    .cart__main .header-logo {
        left: 20px;
    }
    .chk__steps-description span.cart-md-step {
        margin: 0 10px;
    }
    .chk__steps-description span.cart-step {
        margin-right: 10px;
    }
    .chk__steps-description span:nth-child(2) {
        margin: 0 10px;
    }
    .chk__cep-label a.chk__link {
        margin-left: 8%;
        font-size: 11px;
    }
    .chk__creditCard-input.cvv_code {
        width: 40% !important;
    }
}

@media (max-width: 330px) {
    .chk__cep-label a.chk__link {
        margin-left: 3%;
    }
}

@media (max-width: 313px) {
    .cvv_code {
      width: 100%;
    }
}

@media (max-width: 292px) {
    .chk__cep-input {
        width: 100%;
        margin: 0;
    }
    .chk__label-cep {
        margin-top: -5px;
    }
    .chk__small-input,
    .chk__bigger-input {
        width: 100%;
        padding: 0;
    }
}

input[disabled] {
    -webkit-text-fill-color: #555;
    -webkit-opacity: 1;
    color: #555;
}

/* crossbrowser */

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
    @media {
        .chk__order-total,
        .chk__cvv-icon,
        .chk__cvv-msg,
        .chk__heading ,
        .chk__form-control,
        .chk__label,
        .chk__label:after,
        .chk__link,
        .chk__customer-information,
        .chk__edit-link,
        .chk-payment__text,
        .chk__waiting-step__number,
        .chk__waiting-step__message,
        .chk__boleto-text,
        .chk__pix-text,
        .chk__step-description,
        .chk__steps-description span {
            font-family: "Open Sans";
        }

        .chk__order-total-price,
        .chk__cvv-icon,
        .chk__join-link,
        .chk__logout,
        .chk__button,
        .chk__social-login,
        .chk__customer-information strong,
        .chk-payment__text strong,
        .chk-payment__store-name,
        .chk__waiting-step__description {
            font-family: "Open Sans";
            font-weight: 500;
        }
    }
}

.checkbox_div {
    margin-top: 10px;
    margin-bottom: 10px;
}
.multi-card-index, .multi-boleto-creditcard-index {
    background-color: #1cade4;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 4px 12px;
    border-radius: 10px;
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    display: block;
    max-width: 75px;
    text-align: center;
}

.multi-boleto-creditcard-description {
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.no-vertical-margin {
    margin-top: 0px;
    margin-bottom: 0px;
}

.d-error {
    border: 1px solid;
    border-color: #ff2727;
}

.input-btn-error {
    background-color: #ffcfcf !important;
}

.input-group-currency {
    display: block;
}

.input-group-text {
    margin-top: 3px;
    border-radius: 0px 5px 5px 0px;
}

.two-credit-card-msg-success {
    font-size: 13px;
    color: #128c41;
}

.two-credit-card-msg-danger {
    font-size: 13px;
    color: #fd2727;
}

@media only screen and (min-device-width : 395px) {
    .checkbox_div {
        margin-top: 40px;
    }
}

.order__error-icon {
    color: red;
}

#createCreditCardFailedModal .modal-dialog{
    max-width: 500px;
}

#createCreditCardFailedLabel {
    font-weight: bold;
}

@media (max-width: 759px) {
    #createCreditCardFailedModal .modal-dialog{
        margin-top: 50px;
    }
}
@media (min-width: 760px) {
    .button-modal-boleto {
        float: right !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
}
.verified-failed-img {
    width: 60px;
    margin-top: -50px
}

.button-modal-review-payment {
    font-weight: bold !important;
}