.product__infos {
    background: #FFFFFF;
    margin: 2em 0;
}

.product__infos--wrapper {
    padding: 25px;
}

.product__infos--titles {
    border-bottom: 2px solid #0d292f;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.product__infos--title {
    margin-right: 15px;
    font-size: 17px;
    opacity: .5;
    transition: all 300ms ease;
}

.product__infos--title:hover {
    cursor: pointer;
}

.product__infos--title.active {
    color: #0d292f;
    opacity: 1;
}

.product__infos--attribute-value {
    padding: 0px 10px;
}

.product__infos--attribute-name {
    background-color: #f5f4f4;
    font-size: 15px;
    font-weight: bold;
    padding: 5px 10px;
    margin: 10px 0 5px;
    color: #a09f9f;
}

.product__infos--content-attributes {
    display: flex;
    flex-direction: column;
}

.product__infos--tab {
    transition: all 300ms ease;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    overflow-y: hidden;
}

.product__infos--tab.active {
    opacity: 1;
    visibility: visible;
    max-height: none;
    overflow-y: visible;
}