.quantity-control {
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
}
.quantity-control-minus *, .quantity-control-minus {
    color: darkred;
}
.quantity-control-plus *, .quantity-control-plus {
    color: darkgreen;
}
.quantity-control-inactive *, .quantity-control-inactive {
    color:grey;
    cursor: default;
}