.showcase-stores__bg {
    background: url('../img/showcase-stores-bg.jpg') no-repeat center center;
    width: 100%;
    height: 350px;
    top: 0;
    left: 0;
    z-index: -1;
}

.showcase-stores__jumbotron {
    margin-top: 6.5em;
    text-shadow: #444 0 1px 1px;
}

.showcase-stores__jumbotron > h1 {
    font-weight: 900;
    color: #fff;
}

.showcase-stores__jumbotron > p {
    font-weight: 300;
    color: #fff;
}

.showcase-stores__search {
    margin-top: 2em;
}

.showcase-stores__search form input {
    font-family: 'Open Sans', sans-serif;
    color: #225560;
    border: 1px solid #225560;
    padding: 12px 24px;
    font-size: 21px;
    font-weight: 300;
    width: 350px;
}

.showcase-stores__search form button {
    font-family: 'Open Sans', sans-serif;
    background-color: #225560;
    color: #fff;
    border: none;
    font-size: 20px;
    padding: 14px 23px;
    font-weight: 300;
    cursor: pointer;
    margin-left: -4px;
}

.showcase_stores__featured-stores {
    padding: 1.8em 0;
}

.showcase-stores__featured-logo {
    height: 11em;
    background: white;
    transition: all 0.1s ease-out;
    border: 1px solid #ddd;
    position: relative;
}

.showcase-stores__featured-logo img {
    position: absolute;
    max-width: 85%;
    max-height: 85%;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.showcase-stores__all-stores-content {
    width: 12.5%;
    padding: 0 7px;
    float: left;
}

.showcase-stores__all-stores-logo {
    margin-bottom: 16px;
    background: #FFF;
    transition: all 0.1s ease-out;
    border: 1px solid #ddd;
    height: 110px;
    color: transparent;
    line-height: 0;
    position: relative;
}

.showcase-stores__all-stores {
    padding: 1.8em 0;
    background-color: #eee;
}

.showcase-stores__all-stores-logo img {
    max-width: 80%;
    max-height: 80%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.showcase-stores__all-stores-logo:hover {
    border: 1px solid #666;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.showcase-stores__footer footer{
    margin: 0 !important;
}

@media (max-width: 992px) {
    .showcase-stores__featured-logo {
        height: 15em;
        margin-bottom: 30px;
    }

    .showcase-stores__all-stores-content {
        width: 16.65%;
    }
}

@media (max-width: 690px) {
    .showcase-stores__all-stores-content {
        width: 25%;
    }
}

@media (max-width: 440px) {
    .showcase-stores__all-stores-content {
        width: 50%;
        padding: 0 15px;
    }
}
