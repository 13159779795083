.cart-header-content {
    width: 100%;
}

.cart__clear--all {
    font-family: Gotham;
    color:  #848383;
    font-size: 12px;
    font-weight: 300;
    float: right;
}

.cart__clear--all:hover {
    cursor: pointer;
}

.cart-header-heading {
    font-family: Gotham;
    color: #333333;
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 0;
}

.cart-header-qty {
    font-family: Gotham;
    color: #333333;
    font-size: 16.03px;
    font-weight: 300;
    float: right;
    padding: 5px 0;
}

.cart-btn {
    color: #FFF !important;
}

.cart-store-top-line {
    border-top: 1px solid #a7a7a7;
    margin: 0 0 7px 0;
}

.cart-store-end-line {
    border: 0;
    margin: 8px 0 12px 0;
    background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
    height: 1px;
}

.cart__footer-freight {
    width: 66.66666666%;
    padding-right: 15px;
    float: left;
}

.cart__freight-form {
    width: 342px;
}

#shippingCalculate,
#freight-result {
    display: none;
}

.cart-freight-form-input {
    font-family: Gotham;
    color: #848383;
    font-size: 17px;
    font-weight: 300;
    line-height: 1;
    padding: 23px 20px 20px;
}

.cart__freight label.error {
    position: absolute;
    width: 210px;
    line-height: 1;
    padding-top: 5px;
    font-size: 12px;
}

.cart__freight {
    float: right;
}

.freight-info {
    margin-top: 3px;
    font-size: 13px;
    color: #666;
}

.freight-result__CEP {
    font-family: Gotham;
    color: #333333;
    font-size: 16px;
    line-height: 1;
}

.freight-result__CEP a {
    font-family: Gotham;
    color: #009bfe;
    font-size: 12px;
    font-weight: 300;
    text-decoration: underline;    
}

.freight-result__CEP a#changeCEP {
    margin-left: 15px;
}

.freight-result__CEP a:hover {
    cursor: pointer;
}

.freight-result {
    width: 100%;
    margin-top: 24px;
}

.freight-result__content {
    background: #F3f3f3;
    padding: 15px 20px;
    margin-bottom: 20px;
}

.freight-result__content:last-child {
    margin: 0;
}

.shipping-options__label {
    font-family: Gotham;
    color: #333333;
    font-size: 14px;
    font-weight: 100;
    margin-bottom: 0;
    margin-right: 15px;
}

.shipping-options__label-active {
    font-family: Gotham Medium;
}

.freight-result__to {
    font-family: Gotham;
    color: #333333;
    font-size: 16px;
    margin: 2px 0 0;
    float: right;
}

.freight-result__to span {
    font-family: Gotham Medium;
}

.no-shipping-options {
    line-height: 1;
    margin-top: 15px;
    background: #d1ecf1;
    padding: 10px;
    color: #0c5460;
    font-size: 12px;
}

.no-shipping-options i {
    font-size: 15px;
    margin-right: 5px;
}

.cart-btn {
    background: #333;
    border: 1px solid #333;
    font-family: Gotham;
    color: #fffefe;
    font-size: 12px;
    font-weight: 500;
    padding: 16px 12px;
    line-height: 1;
    z-index: 1 !important;
}

.cart-btn:hover {
    color: #FFF;
}

.cart-btn-finish {
    border: transparent;
    border-radius: 8px;
    background-color: #26b70e;
    padding: 21px 0 18px;
    width: 100%;
    font-family: Gotham Medium;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1;
}

.activate-coupon {
    margin: 0;
}

.coupon-box {
    margin-right: 50px;
}

.coupon-code {
    max-width: 118px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 4px 8px;
    height: initial;
    float: left;
}

.btn-coupon {
    padding: 8.7px 8px;
    background: #333333;
    color: #FFF;
    position: relative;
    z-index: 1;
    margin-left: -2px;
    border: 2px solid transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    float: left;
    font-size: 12px;
    line-height: 1;
}

.coupon-message {
    font-size: 11px;
    color: #fd5f5f;
    font-family: calibri;
    font-weight: 500;
    float: left;
    width: 100%;
}

.cart-table .row {
    margin-left: 0;
    margin-right: 0;
}

select#shippingOptions {
    width: 206px;
    margin-left: 10px;
}

.shipping-content {
    float: right;
}

.shipping__label {
    font-weight: 700;
}

.cupon-content {
    float: right;
    margin-left: 30px;
}

.cart__store-name {
    margin-bottom: 0;
    font-family: Gotham;
    color: #333333;
    font-size: 13px;
    font-weight: 300;
    margin-right: 50px;
}

.freight-result .cart__store-name {
    font-size: 17px;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 10px;
    margin-right: 0;
    line-height: 1;
}

.cart__store-name a {
    font-family: Gotham Medium;
    color: #333333;
}

.min-value {
    font-family: Gotham;
    font-size: 12px;
    font-weight: 300;
    margin: 0;
}

.cart-item {
    padding: 12px 0 14px;
}

.cart-item-end-line {
    margin: 0 15px;
    border-top: 1px solid #a7a7a7;
}

.cart__img-link {
    display: block;
}

.cart__img-link img {
    width: auto;
    max-width: 100%;
    max-height: 68px;
    display: block;
    margin: 0 auto;
}

.cart__img {
    display: inline-block;
    vertical-align: middle;
}

.cart__product-title {
    font-family: Gotham;
    color: #333333;
    font-size: 13px;
    font-weight: 300;
}

.cart__small-variant {
    margin-bottom: 0;
}

.cart__small-variant small{
    font-family: Gotham;
    color: #333333;
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
}

.cart__small-variant small b {
    font-family: Gotham Medium;
}

.cart-product-quantity {
    width: 60px !important;
    display: inline-block !important;
    float: none !important;
    text-align: center;
    border: 1px solid #a7a7a7;
    font-family: Gotham;
    color: #333333;
    font-size: 12px !important;
    font-weight: 300;
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.cart__btn-plus-minus {
    color: #666;
    border-radius: 50%;
    line-height: 15px;
    font-size: 20px;
    vertical-align: top;
    margin-top: 9px;
}

.cart__btn-plus-minus:hover{
    cursor: pointer;
}

.qty__minus {
    margin-right: 8px;
}

.qty__plus {
    margin-left: 8px;
}

.cart__remove-item {
    margin-left: -4px;
    margin-top: 4px;
    font-family: Gotham;
    color: #333333;
    font-size: 12px;
    font-weight: 300;
    text-decoration: underline;
}

.cart__title-itens {
    padding-bottom: 8px;
    font-family: Gotham;
    color: #333;
    font-size: 15px;
    font-weight: 500;
}

.cart__prices {
    font-family: Gotham Medium;
    color: #333333;
    font-size: 14px;
}

.cart__footer {
    padding-top: 18px;
    width: 100%;
    border-top: 1px solid #a7a7a7;
}

.cart__footer-total {
    float: right;
    width: 33.33333334%;
    padding-left: 15px;
}

.cart__footer-prices {
    border: 1px solid #a7a7a7;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    font-family: Gotham, sans-serif;
}

.cart__footer-prices p {
    color: #333;
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart__footer-prices span {
    font-family: Gotham Medium, sans-serif;
    float: right;
    display: flex;
    flex: none;
}

.cart__continue-shopping, .cart__share {
    display: block;
    width: 100%;
    border: 1px solid #a7a7a7;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    font-family: Gotham;
    color: #333333;
    font-size: 12px;
    margin: 12px 0;
}

.cart__share {
    color: #26b70e;
    border: none;
}

.cart__continue-shopping:hover, .cart__share:hover {
    text-decoration: none;
    cursor: pointer;
}

.cart__clear-all {
    font-family: Gotham;
    color: #848383;
    font-size: 12px;
    font-weight: 300;
    text-decoration: underline;
    float: right;
    display: block;
}

.coupon-box__content {
    display: none;
}

.coupon-link {
    font-family: Gotham;
    color: #333333;
    font-size: 12px;
    font-weight: 300;
    margin-right: 50px;
}

.coupon-link:before {
    content: "";
    width: 11px;
    height: 11px;
    display: inline-block;
    margin-right: 7px;
    background-image: url(../img/icon/common/cart-tag.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.coupon-link:hover {
    cursor: pointer;
}

.coupon-link i {
    color: #4eaf79;
}

.only-one-option-shipping {
    color: #666;
    line-height: 34px;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

.cart-message-is-empty {
    margin: 100px 0;
}

.cart-message-is-empty .cart__continue-shopping {
    width: 20%;
    margin: 0 auto;
}

/* crossbrowser */

@media screen and (min-width:0\0) {
    .form-control {
        padding: 9px 10px;
    }
    .cart-freight-form-input {
        height: 45px;
    }
}

.cart__clear--all,
.cart-header-heading,
.cart-header-qty,
.cart-freight-form-input,
.freight-result__CEP,
.freight-result__CEP a,
.shipping-options__label,
.freight-result__to,
.cart-btn,
.cart__store-name,
.min-value,
.cart__product-title,
.cart__small-variant small,
.cart-product-quantity,
.cart__remove-item,
.cart__title-itens ,
.cart__footer-prices,
.cart__continue-shopping,
.cart__share,
.cart__clear-all,
.coupon-link {
    font-family: "Open Sans", sans-serif;
}

.shipping-options__label-active,
.freight-result__to span,
.cart-btn-finish,
.cart__store-name a,
.cart__small-variant small b,
.cart__prices,
.cart__footer-prices span {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

#share-cart-modal .modal-content {
    font-family: 'SimplonBP';
    font-weight: 500;
}

#share-cart-modal .modal-content .modal-body{
    font-size: 18px;
    padding: 30px 30px 0;
}
#share-cart-modal .modal-content .modal-footer{
    padding: 16px 30px 14px;
}

#share-cart-modal .modal-content .modal-body .input-group{
    width: 100%;
    padding-top: 28px;
    padding-bottom: 33px;
}

#share-cart-modal .modal-content #finishOrder {
    margin-bottom: 20px;
    background-color: #26b70e;
}

#share-cart-modal .modal-content #finishOrder.disabled {
    background-color: #92db8e;
}

#share-cart-modal h2{
    /* color:#26b70e; */
    color: #009E0B;
    font-family: 'Simplon Oi';
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 30px;
}

#share-cart-modal .contianer__share__link_success{
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    display: none;
    padding-bottom: 8px;
    background-color: #26b70e;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-top: 10px;
}

#share-cart-modal  .share__link__error{
    color: #ff2727;
    font-size: 14px;
    font-weight: 400;
}

#share-cart-modal .close {
    background-color: #000;
    border: 1px solid #fff;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    opacity: 1;
    color: #fff;
    z-index: 1;
    position: absolute;
    right: -10px;
    top: -10px;
    font-weight: 100;
    font-size: 20px;
    padding-bottom: 5px;
}

#share-cart-modal .modal-footer .btn.btn-primary {
    background-color: #009E0B;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    height: 46px;
}

#share-cart-modal #qr-code-link-cart{
    background-color: #009E0B;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    height: 46px;
    border-radius: 0 6px 6px 0;
}

#share-cart-modal #qr-code-link-cart.disable{
    background-color: #cccccc;
}

#share-cart-modal .container-qrcode{
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
}

#share-cart-modal .container-qrcode img{
    width: 200px;
}

#share-cart-modal .container-fiber{
    display: none;
}

#share-cart-modal .modal-footer .form-group {
    min-height: 0;
}

#share-cart-modal .modal-footer .form-control[disabled] {
    background-color: transparent;
    border-radius: 6px 0 0 6px;
    height: 46px;
    font-size: 15px;
    cursor: default;
}

#share-cart-modal .modal-footer #share-link-cart.disable{
    background-color: #cccccc;
}

#share-cart-modal .modal-footer .form-control[disabled].success {
    color: #009E0B;
    -webkit-text-fill-color: #009E0B;
}

#share-cart-modal .switch {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 50px;
    margin-bottom: 0;
    margin-left: 10px;
}
#share-cart-modal .container-has-fiber{
    display: flex;
    flex-flow: row wrap;
    align-content: center;
}

#share-cart-modal .switch input {
    display: block;
    position: absolute;
    z-index: 1;
    opacity: 0;
    width: 50px;
    cursor: pointer;
}
#share-cart-modal #share-link-input-fiber{
    font-family: 'Simplon Oi';
    width: 100%;
    border-radius: 6px 0 0 6px;
    height: 46px;
    font-size: 15px;
}
#share-cart-modal #share-link-input-hidden{
    display: none;
}
#share-cart-modal .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
}

#share-cart-modal .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 16px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 16px;
}

#share-cart-modal .switch input:checked + .slider {
    background-color: #009E0B;
    text-transform: uppercase;
}

#share-cart-modal .switch input:checked + .slider:before {
    transform: translateX(26px);
}

#share-cart-modal .slider.round {
    border-radius: 34px;
}

#share-cart-modal .slider.round:before {
    border-radius: 50%;
}

/* Warnings */
#box_warning_change_cart_js .container-alert-warning{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#box_warning_change_cart_js .container-alert-warning-text{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#box_warning_change_cart_js img{
    height: 25px;
    width: 25px;
}

#box_warning_change_cart_js .btn-warning{
    background-color: #ffcc00;
    color: #000;
    border: 1px solid #ffcc00;
}

#box_warning_change_cart_js .container-alert-warning-text ul{
    list-style-type: disc;
    padding-left: 15px;
    margin-bottom: 0;
}

#box_warning_change_cart_js .container-alert-warning-text div:last-child{
    padding-left: 15px;
}

#box_success_change_cart_js div p{
    margin-bottom: 0;
    color: #508550;
}