.restriction__mask {
    display: none;
}

.geo-restriction__header {
    padding: 15px 0;
    background: #FFF;
}

.geo-restriction__header-login-info {
    margin: 0 0 0 30px;
}

.geo-restriction__header-login-info li a {
    padding: 0 6px;
    color: #999;
    font-size: 16px;
}

.geo-restriction-logo img {
    max-width: 200px;
    max-height: 80px;
}

.geo-restriction__main {
    position: relative;
    background: #000;
}

.geo-restriction__main-bg {
    background-image: url(../img/page-restriction/geo-restriction-bg.jpg);
    background-size: cover;
    background-position: bottom;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.geo-restriction__main h2 {
    color: #FFF;
    padding: 200px 50px 30px;
    margin: 0;
    font-weight: 700;
    font-size: 40px;
    position: relative;
    z-index: 2;
}

.geo-restriction__main h3 {
    color: #FFF;
    font-size: 30px;
    font-weight: 100;
    position: relative;
    z-index: 2;
}

.geo-restriction__main form {
    width: 50%;
    margin: 0 auto;
    padding: 50px 0 160px;
    position: relative;
    z-index: 2;
}

.geo-restriction__zip-code {
    width: 100%;
    padding: 20px;
}

.area-check__cep-button {
    padding: 10px 25px;
    position: absolute;
    top: 62px;
    right: 1px;
    border: transparent;
    background: #FFF;
    right: 0;
    border: transparent;
    background: transparent;
    color: #666;
    font-weight: 700;
    text-transform: uppercase;
    border-left: 2px solid #888;
}

.geo-restriction__footer {
    padding: 20px 0 10px;
    background: #FFF;
}

.footer__copyright {
    color: #666;
    font-size: 15px;
    margin: 0;
}

.geo-restriction__footer .right {
    float: right;
}

.geo-restriction__footer .logo-smplaces {
    margin-top: 8px;
}

.ssl {
    background-image: url(../img/icon/common/lets-encrypt-footer.png);
    width: 94px;
    height: 22px;
    display: block;
    background-size: cover;
}

.logo-smplaces {
    background-image: url(../img/icon/common/logo_smplaces.png);
    width: 70px;
    height: 10px;
    display: block;
    background-repeat: no-repeat;
    -webkit-filter: brightness(40%);
    filter: brightness(40%);
}

.restriction__mask {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.7);
    z-index: 99999;
}

.restriction__message {
    background-color: #fff;
    width: 80%;
    max-width: 680px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px 50px 30px;
}

.restriction__message::before {
    content: ':(';
    display: inline-block;
    margin-bottom: 10px;
    font-family: 'Open Sans';
    color: #225560;
    font-size: 80px;
    font-weight: 700;
}

.restriction__message-text {
    font-family: 'Open Sans';
    color: #333;
    font-size: 20px;
    font-weight: 300;
    margin: 0;
}

.restriction__close {
    font-family: 'Open Sans';
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    border-radius: 50%;
    background-color: #225560;
    width: 40px;
    height: 40px;
    border: none;
    position: absolute;
    line-height: 0px;
    top: 25px;
    right: 25px;
    text-shadow: 1px 0px 0px #fff, -1px 0px 0px #fff;
}

.restriction__mask--active {
    display: block;
}
