.authentication-panel {
    margin-top: 55px;
    margin-right: auto;
    margin-left: auto;
    width: 45%;
    min-width: 320px;
    max-width: 100%;
}

.center-div,
.center2-div {
    float: none;
    margin-right: auto;
    margin-left: auto;
    display: block;
}

.center-div h2,
.center2-div h2,
.center-div h3,
.center-div a {
    text-align: center;
}

.center-div h2 {
    padding-bottom: 20px;
}

.center-div input {
    width: 80%;
    height: 30px;
    padding: 0 5px;
    margin-bottom: 20px;
}

.center-div div {
    width: 70%;
    text-align: center;
    display: block;
    margin: 0 auto;
}

.center-div div input, .center-div div form label {
    width: 100%;
}

.center-div div div, .center-div div form {
    float: left;
    text-align: left;
}

.center-div div div input {
    width: 90%;
}

.center-div div a {
    width: auto;
    float: right;
    text-decoration: underline;
}

.amb-seg {
    font-size: 28px;
    text-align: right;
    margin: 12px 20px 0 0;
}
