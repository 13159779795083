.megamenu {
    position: relative;
    padding: 0 !important;
}

.megamenu span {
    padding: 0 20px;
    background: #FFF;
    position: relative;
    z-index: 2;
}

.megamenu:hover > span {
    cursor: pointer;
}

.megamenu:hover > .megamenu__list {
    display: block;
}

.megamenu__list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 130%;
    background: white;
    z-index: 1;
    padding-top: 10px;
    min-height: 348px;
    box-shadow: 0px 0px 40px #AAA;
}

.megamenu__first-level__item {
    padding: 5px 20px;
}

.megamenu__first-level__item.view-all {
    margin-top: 5px;
    border-top: 1px solid #CCC;
    padding: 13px 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.megamenu__first-level__item-text {
    position: relative;
}

.megamenu__first-level__item-text:after {
    content: "\f054";
    font-family: FontAwesome;
    font-size: 9px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 0;
    width: 6px;
    height: 12px;
}

.megamenu__first-level__item-text.hasnt-drop:after {
    display: none;
}

.view-all .megamenu__first-level__item-text {
    font-weight: 700;
}

.megamenu__first-level__item:hover > .megamenu__second-mega__level {
    display: block;
}

.megamenu__second-mega__level {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    background: linear-gradient(to right, #CCC -190px, #ffffff 200px, #ffffff 100%);
    padding: 15px;
    box-shadow: 20px 0px 40px #AAA;
}

.megamenu__second-mega__level {
    display: none;
}

.megamenu__second-mega__level-column {
    display: table-cell;
    padding: 0 14px;
}

.megamenu__second-mega__level-item,
.megamenu__third-mega__level-item {
    height: 31.5px;
    width: 120px;
    display: block;
    position: relative;
}

.megamenu__second-mega__level-item__second-text,
.megamenu__third-mega__level-item-text {
    line-height: 1;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.megamenu__second-mega__level-item__second-text {
    font-weight: 700 !important;
}

.megamenu__third-mega__level-item-text.link:before {
    content: "\f054";
    font-family: FontAwesome;
    font-size: 7px;
    position: relative;
    top: -2px;
    margin-right: 5px;
}

/* all-categories */

.all-categories__first-level {
    font-size: 25px;
    font-weight: 600;
    margin-top: 2em;
}

.all-categories__second-level {
    font-size: 18px;
    margin-bottom: 5px;
}

.all-categories__third-level {
    font-weight: 300;
    font-size: 14px;
}
