.ie8 .ie_show {
	display: block !important;
}

.addr__tooltip {
	right: 4px;
	top: 7px;
}

.addr__tooltip i {
	font-size: 1.2em;
}

@media screen and (max-width: 767px) {
	.addr__tooltip:hover::after {
		left: -210px;
	}

	.addr__tooltip:hover::before {
		border-color: transparent transparent transparent #212735;
	    left: -10px;
	}
}

@media screen and (max-width: 991px) {
	.addr__tooltip--alternative-qry:hover::after {
		left: -210px;
	}

	.addr__tooltip--alternative-qry:hover::before {
		border-color: transparent transparent transparent #212735;
	    left: -10px;
	}
}

.addr__tooltip--checkout {
	right: 7px;
	top: 34px;
}

.addr__tooltip--edit {
	right: 22px;
	top: 29px;
}

.registration-both #form-pj {
	display: none;
}

label.customer_type {
	font-size: 1em;
}

label.customer_type input {
	margin-right: .5em;
}

/* Painel de exibição da mensagem sobre política de privacidade e termos de uso do site */
.regist__statement{
	margin-top: 15px;
	margin-bottom: 10px;
}