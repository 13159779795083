.customer-navigation__item-title {
    padding: 11px 16px;
    border: 1px solid #cccccc;
    background: #FAFAFA;
    color: #555;
    font-size: 16px;
}

.customer-navigation__option,
.customer-navigation__logout {
    font-family: "Open Sans";
    padding: 10px 19px;
    display: block;
    font-weight: 500;
    color: #777;
}

.customer-navigation__option--active {
    color: #4eaf79;
    font-weight: 500;
}

.customer-navigation__item {
    border-bottom: 1px solid #DDD;
}

.customer-navigation__logout,
.customer-navigation__logout:hover,
.customer-navigation__logout:focus {
    text-decoration: none;
}

@media (max-width: 767px) {
    .customer-navigation ul {
        margin-bottom: 30px;
    }
    li.customer-navigation__item-title {
        position: relative;
    }
    .customer-navigation ul li.customer-navigation__item-title:first-child:after {
        content: "\f078";
        font-family: FontAwesome;
        font-size: 10px;
        right: 15px;
        top: 15px;
        position: absolute;
        color: #888;
    }
    .customer-navigation ul li:not(:first-child),
    .customer-navigation ul br {
        display: none;
    }
    ul.mobile-active li.customer-navigation__item-title:first-child:after {
        content: '\f077';
    }
    ul.mobile-active li:not(:first-child),
    ul.mobile-active br {
        display: block;
    }

}
