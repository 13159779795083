.breadcrumb-area {
    padding: 15px 0;
    background:#fff;
}

ul[role=navigation] {
    margin: 0;
}

.breadcrumb {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    margin: 0;padding: 0;
}
.breadcrumb ul li {color: #999;
    display: inline-block;
    margin: 0 4px;
    padding: 0 8px 0 0;
    position: relative;
    text-transform: uppercase;
}
.breadcrumb ul li h1 {
    font-size: inherit;
    font-weight: inherit;
}
.breadcrumb ul li::before {
    left: -11px;
    position: absolute;
    top: 0px;
}
.breadcrumb ul li:first-child{margin-right:-8px}
.breadcrumb ul li:first-child::before {
    content: "";
}
.breadcrumb ul li a {
    font-family: "Open Sans";
    color: #0D292F;
}
.breadcrumb ul li:nth-child(n+3)::before {
    content: "/";
    display: inline-block;
}
.breadcrumb ul li a i {
    padding-right: 3px;color:#999
}

.breadcrumb__category-title {
    float: right;
    font-size: 14px;
    line-height: 0;
    margin: 0;
    margin-top: -9px;
    font-weight: 700;
    color: #222;
    font-family: "Open Sans", sans-serif;
}

@media (max-width: 1140px) {
    .breadcrumb ul li {
        font-size: 0.76em !important;
    }

    i.breadcrumb-arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    .breadcrumb-area {
        border-top: 1px solid #CCC;
    }
}
